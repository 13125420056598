import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";
import mq from "@styles/media";

import Section from "../../components/Section";
import Wrapper from "../../components/Wrapper";
import Image from "../../components/Image/Image";
import Link from "../../components/Link";

import { IArticle } from "@types";


interface ArticlesListProps {
    articles: IArticle[];
}

interface ArticlesListItemProps {
    article: IArticle;
}

const ArticlesList: React.FC<ArticlesListProps> = ({ articles }) => {
  if (!articles) return null;

  return (
    <ArticleSection>
        <Wrapper>
            <Grid>
            {articles.map((article, index) => <ListItem key={index} article={article} /> )}
            </Grid>
        </Wrapper>
    </ArticleSection>
  );
};

export default ArticlesList;

const ListItem: React.FC<ArticlesListItemProps> = ({ article }) => {
    if (!article) return null;

    const imageSource = article.hero.regular;
    const hasHeroImage =
        Object.keys(imageSource).length !== 0 && imageSource.constructor === Object;

    const date = new Date(article.dateForSEO);
    const formattedDate = date.toLocaleString('hu-HU',{ dateStyle: "long" });

  return (
    <Link to={`/blog/${article.slug}`} data-a11y="false">
        <Card>
            <CardImage>
                <BlogImage src={hasHeroImage ? imageSource : `https://dummyimage.com/640x360/ddd/aaa"`} alt={article.title} />
            </CardImage>
            <Content>
                <Title>{article.title}</Title>
                <Excerpt>{article.excerpt}</Excerpt>
                <Details>
                    <Detail>{formattedDate}</Detail>
                    <Detail>{Math.ceil(article.timeToRead.minutes)} perc olvasás</Detail>
                </Details>
            </Content>
        </Card>
    </Link>
  );
};

const ArticleSection = styled(Section)`
    ${tw`clear-both py-8 m-0 mx-auto sm:py-10 md:py-12 lg:py-16 xl:py-24`};
`;

const Grid = styled.div`
    ${tw`grid grid-cols-1 gap-8 gap-y-20 md:gap-y-16 md:grid-cols-2 xl:grid-cols-3`};
    ${tw`gap-8 md:gap-x-12 sm:grid-cols-2 lg:grid-cols-3`};
`;

const CardImage = styled.div<{full?: boolean; color?: string; colorOpaque?: string; priority?: boolean}>`
    transition: ${p => p.theme.transitionValue};
    ${tw`h-64 min-w-full overflow-hidden rounded-t-md rounded-b-md`};
    ${p => p.full && tw`absolute h-full rounded-md`};
    ${p => p.priority && `border: 6px solid ${p.theme.colors.accent};`};
`;

const Card = styled.div`
    ${tw`w-full h-full max-w-sm m-auto rounded-md md:max-w-full`};

    &:hover ${CardImage} {
        ${tw`shadow-2xl`};
        top: -1px;
    }
`;

const Content = styled.div`
    ${tw`w-full py-4 mx-auto my-0 md:py-4 z-2`};
`;

const Title = styled.h3`
    ${tw`mt-3 text-lg font-semibold leading-none tracking-normal uppercase normal-case md:text-xl`};
`;

const limitToTwoLines = css`
    text-overflow: ellipsis;
    overflow-wrap: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;

    ${mq.to_tablet`
        -webkit-line-clamp: 3;
    `}
`;

const Excerpt = styled.p`
    ${limitToTwoLines};
    ${tw`text-gray-600 text-base mt-3`};
`;

const Details = styled.div`
    ${tw`flex flex-col items-center sm:flex-row sm:justify-between mt-4 items-start`};
`;

const Detail = styled.span`
    ${tw`text-gray-500 text-base font-semibold`};
`;

const BlogImage = styled(Image)`
    ${tw`min-w-full min-h-full`};
`;
