import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SEO from "../components/SEO/SEO";
import Layout from "../components/Layout";
import Paginator from "../components/Navigation/Navigation.Paginator";

import ArticlesHero from "../sections/articles/Articles.Hero";
import ArticlesList from "../sections/articles/Articles.List";

import { Template } from "@types";

const ArticlesPage: Template = ({ location, pageContext }) => {
  const articles = pageContext.group;
  const authors = pageContext.additionalContext.authors;

  return (
    <Layout>
        <SEO slug={location.pathname} title={`iFaktor Blog | ${pageContext.index}. oldal`} isOrganization isSubPage />
        <Wrap>
            <ArticlesHero />
            <ArticlesList articles={articles} />
            <ArticlesPaginator show={pageContext.pageCount > 1}>
                <Paginator {...pageContext} />
            </ArticlesPaginator>
        </Wrap>
    </Layout>
  );
};

export default ArticlesPage;

const Wrap = styled.div`
    ${tw`relative w-full h-full`};
`;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && tw`my-4 mt-0 mx-auto justify-center flex`}
`;
