import React from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/core";
import tw from "twin.macro";

import Section from '../../components/Section';
import Wrapper from "../../components/Wrapper";
import Transitions from "../../components/Transitions";

import BlogSVG from "@images/svg/blog.inline.svg";

const ArticlesHero: React.FC<{}> = () => {
  return (
    <BlogSection flex id="landing">
        <InfluencerWrapper left>
            <Transitions.JS.FadeInUpGroup>
                <Header>Az iFaktor Agency legfrissebb hírei</Header>
                {/* <Subtitle>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos harum alias iste, expedita nihil obcaecati hic voluptatibus ipsum voluptatem? Placeat!
                </Subtitle> */}
            </Transitions.JS.FadeInUpGroup>
        </InfluencerWrapper>
        
        <Transitions.JS.FadeInGroup duration={1000} delay={300} contents removeStylesAfter>
            <InfluencersIcon right />
        </Transitions.JS.FadeInGroup>
    </BlogSection>
  );
};

export default ArticlesHero;

const BlogSection = styled(Section)<{form?: boolean; flex?: boolean}>`
    ${tw`bg-gray-200 top-16 md:top-0`};

    ${p => p.form && tw`mb-32 md:-mb-32`};
`;

const InfluencerWrapper = styled(Wrapper)`
    ${tw`md:w-7/12 lg:w-6/12`};
`;

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.accent};
    }
`;

const Header = styled.h1`
    ${tw`text-4xl font-semibold leading-none tracking-tight md:text-5xl`};

    ${spanColorAccent};
`;

const Subtitle = styled.p`
    ${tw`my-8 mb-4 text-lg text-gray-700 md:text-xl`};
`;

const ContentSVG = css`
    ${tw`w-9/12 h-full max-w-full md:absolute md:w-4/12`};
`;

const RightSVG = css`
    ${tw`md:right-32`};
`;

const LeftSVG = css`
    ${tw`md:left-32`};
`;

const InfluencersIcon = styled(BlogSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};

    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;